/* eslint-disable import/prefer-default-export */
import ReactDOM from "react-dom/client";
import "intersection-observer";

// export const wrapRootElement = ({ element }) => (
//   <React.StrictMode>{element}</React.StrictMode>
// );

// eslint-disable-next-line no-unused-vars
import "./src/i18n/i18next";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
